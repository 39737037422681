// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/lib/components/Input.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/lib/components/Input.tsx");
  import.meta.hot.lastModified = "1716201722432.28";
}
// REMIX HMR END

import clsx from 'clsx';
import { forwardRef } from 'react';
export const Input = forwardRef(_c = ({
  className,
  label,
  labelRight,
  errors,
  type,
  ...props
}, ref) => {
  return <label className="form-control w-full">
        {label && <div className="label">
            {label && <div className="label-text">{label}</div>}
            {labelRight && <div className="label-text-alt">{labelRight}</div>}
          </div>}
        <input className={clsx('input-bordered w-full', {
      'input-error': errors && errors.length > 0,
      input: type !== 'file',
      'file-input file-input-primary normal-case': type === 'file'
    })} ref={ref} type={type ?? 'text'} {...props} />
        {errors && errors.length > 0 && <div className="label">
            <div className="label-text text-sm text-error">
              {errors.map(error => <div key={error}>{error}</div>)}
            </div>
          </div>}
      </label>;
});
_c2 = Input;
Input.displayName = 'Input';
var _c, _c2;
$RefreshReg$(_c, "Input$forwardRef");
$RefreshReg$(_c2, "Input");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;